import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTiktok, faLink, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import toast, { Toaster } from 'react-hot-toast';

function copyToClipboard() {
  // Copy the text inside the text field
  navigator.clipboard.writeText("hello@billed-app.com");

  // Alert the copied text
  toast.success("Email copied to clipboard", {
    position: 'bottom-left',
    iconTheme: {
      primary: '#00A97F'
    }
  });
}

function Footer() {

  return (
    <footer className="bg-cloud w-screen border-t-2">

      <div><Toaster/></div>

      <div className="sm:mx-auto max-w-6xl py-6 px-6 flex flex-col sm:flex-row gap-4 sm:gap-0 items-start sm:items-center sm:justify-between lg:px-8">

        <div className="flex gap-4 sm:gap-6 sm:mt-0 flex-row sm:flex-row justify-center items-center">
          <a href="https://www.instagram.com/billed.app" rel="noreferrer" target="_blank" className="m-0 transition hover:opacity-75">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href="https://www.tiktok.com/@billedapp" rel="noreferrer" target="_blank" className="m-0 transition hover:opacity-75">
            <FontAwesomeIcon icon={faTiktok} />
          </a>
          <a href="https://linkedin.com/company/99340415" rel="noreferrer" target="_blank" className="m-0 transition hover:opacity-75">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <button onClick={copyToClipboard} className="m-0 transition hover:opacity-75">
            <FontAwesomeIcon icon={faEnvelope} />
          </button>
        </div>

        <div className='flex gap-6'>
          <a href="/privacy-policy">Privacy Policy</a>
          <a href="/terms-of-service">Terms of Service</a>
        </div>

        {/* Copyright */}
        <div className="justify-center">
          <p className="text-center text-base">
            © 2024 Billed, LLC
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
