import React from 'react'

function TermsOfService() {

  const text = `Last updated: January 21, 2024
  Billed is a bill-splitting solution designed for friends, colleagues, and family to conveniently manage and divide expenses. Records of bills, payments, and debts on Billed are for informal tracking purposes and should not be considered legally binding contracts. We provide the Billed service "as-is," without any warranty. As Billed is continuously evolving, some aspects of our website and app may not function as intended or may undergo changes. Features and services may be modified, discontinued, or limited at any time. We request your understanding and patience, and we are committed to offering the best support possible to enhance your experience with Billed.

  # Terms Of Use

  **Who can use Billed:** You may use our services only if you agree to these Terms of Service and to the processing of your information as described in our Privacy Policy. You may not use Billed if you are barred from receiving services under the laws of the applicable jurisdiction, if we previously banned your account for violation of these terms, or if you are under the age of 13.
  **Description:** Billed (the Service) is crafted to assist you in managing informal debts, expenses, and bills, by enabling users to note down details about who they owe, who owes them, and the reasons behind these debts. The Service simplifies the calculations involved in bill splitting, offers non-binding advice for equitable sharing, and retains this data for future reference. You acknowledge and agree that this service is provided AS-IS and on an AS AVAILABLE basis.
  **Informal debts:** The mobile app allows users who share a group or are friends on the service to post debts and payments as they please, using wiki-style rules with no explicit permissions. These are calculated into a determination of debts by the Service. You agree that you understand these debts are not legally binding, and represent informal information sharing among roommates, friends, or family. You agree that the Service cannot guarantee the accuracy of information entered by users.
  **Conditions of use:** You agree that you will use the Service for its intended purpose, and in compliance with all applicable laws and regulations: local, state, and national, and international, as applicable. You agree not to use the service in a fraudulent, disruptive, aggressive, manipulative, for money laundering, or any other inappropriate matter. Billed reserves the right, but has no obligation, to investigate your use of the service for compliance with appropriate use and terminate your access to the Service, or in order to comply with law, regulation, legal process or government request.
  **What you represent:** You agree to represent yourself honestly, that all of the information you provide is true, correct, and current, to the best of your knowledge, and that you have the necessary rights, power, authority, and age to agree to these Terms and to perform the acts required of you by the agreement.
  **No warranty:** Billed Inc. does not represent that the Service is error-free, complete, or can be relied upon. THE SERVICE IS PROVIDED 'AS IS' AND 'AS AVAILABLE' WITH NO WARRANTY OF ANY KIND AND YOU ARE USING THE SERVICE AT YOUR OWN RISK. Billed INC DISCLAIMS ANY WARRANTY, IMPLIED OR OTHERWISE, REGARDING THE SERVICE, INCLUDING WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT, INCLUDING FOR Billed PRO.
  **Termination and Refusal of Service:** Your access to the Service may be terminated at any time, for any reason, at Billed's sole discretion. This includes the right to ban any person from the service who violates the Billed Content Standards described below..
  **User Posted Content:** When using Billed’s products and services, you may have the ability to post content (e.g., payment descriptions, comments, Group/User Avatars, expense receipts). You are solely responsible for all content that you provide, post, upload or submit. We are not responsible for evaluating the accuracy, truthfulness, usefulness, legality, safety, morality or applicability of any content posted by users on Billed. Billed may, in its sole discretion, reject or remove any content that violates our content standards or for any other reason we deem the content inappropriate. Billed shall have no obligation to monitor content posted, uploaded or submitted by a user, but may do so at its sole discretion. Billed is not responsible for any failure or delay in removing any such content.
  **Billed Content Standards:** When sharing content on Billed, you may not share content that:
  • You do not have the right to share, or that is invasive of another person's right of privacy or right of publicity. You may not share content that includes personal information meant to harass, blackmail, or otherwise target another person.
  • Is false, misleading, or deceptive. For example, you cannot change your User Avatar to misrepresent yourself as another person in a harmful way.
  • Is obscene, harmful, abusive, or offensive. We do not allow nudity on Billed.
  • Incites violence or hatred toward an individual or group of individuals for any reason and especially if based on race or ethnic origin, religion, nationality, disability, gender, age, veteran status, or sexual orientation/gender identity. We will not tolerate content that contains credible threats or hate speech.
  • Is illegal, such as criminal activity, terrorism, obscenity, child pornography, human exploitation, gambling, drug use, firearms or ammunition, and piracy.
  • Is spammy, or promotes or engages in Pyramid schemes, network marketing, and referral marketing programs.
  • In Billed’s sole discretion causes or exposes users or Billed to real or potential harm or liability.
  **Privacy and Security:** In order to provide the services to you we collect some of your Personal Information. We describe our collection and use of personal information in our Privacy Policy. Please review this policy. You must agree to the processing of your data as laid out in that policy in order to use our services. You agree that Billed Inc. may disclose personal information provided to us, including content of emails, messages, and data entered into the site, if required to by law or government request such as a warrant, or as specified in the Privacy Policy. You must be 13 years or older to use this service. You are responsible for preventing unauthorized access to your account.
  **No Liability:** UNDER NO CIRCUMSTANCES WILL Billed INC BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR EXEMPLARY DAMAGES (INCLUDING EMOTIONAL DISTRESS) ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE SERVICE, WHETHER OR NOT YOU HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. UNDER NO CIRCUMSTANCES SHALL Billed INC. BE LIABLE TO YOU FOR ANY AMOUNT FOR SERVICES RENDERED PURSUANT TO THIS AGREEMENT.
  **Indemnification:** You agree to hold Billed Inc., its employees, agents, and representatives, as harmless and indemnify Billed Inc. from any third party claim arising from or in any way related to your use of the Service, including liability or expense arising from all claims, losses, damages (actual and consequential), suits, judgments, litigation costs, and attorney fees, of every kind and nature.
  **Modifications to the Agreement:** You agree that Billed Inc may modify this agreement at any time, effective immediately, by posting a new agreement. You agree that even if you have not personally visited the website with the new terms of this agreement, that they may have force immediately.
  **Severability:** If any part of this agreement is held to be invalid or unenforceable, the provision shall be deemed to be superseded by a valid enforceable provision in its place that closely matches the intent of the original agreement, and all other parts of the agreement shall be enforced.
  **Exercise:** You agree that the failure of Billed Inc. to exercise or enforce any part of this agreement and terms does not constitute a waiver of the rights of provisions of this agreement.
  **Jurisdiction:** You agree that this entire agreement (including Privacy Policy) shall be interpreted according to the laws of the State of Massachusetts.
  **Entire agreement:** These Terms comprise the entire agreement between you and Billed Inc. with respect to the above subject matter.
  **External payment services:** Users have the ability to access, link to (including deep links), or integrate with external payment services that enable you to send and receive payments outside the Billed website or mobile applications. These links and integrations are provided on an-is and as available basis by the underlying payment service, and you agree that Billed is in no way responsible or liable for transactions and service provided by a third party linked to from Billed's service. These services include but are not limited to the following:
  • **PayPal:** Some users may have access to features that interact with PayPal. These features are built on PayPal's API, and currently include deep links to prepopulate some payment information (email address, amounts, notes fields) within the PayPal interface. These features can also automatically record payments and their status on Billed's interface with information provided by PayPal to Billed via API and or deep link. You agree and guarantee that: (a) you will only use the PayPal payment feature with other PayPal users (b) You agree that you will only use these features if you are a US resident, using PayPal with other US residents. (c) You will only send $USD to other US residents, up to a limit of $500 USD per week. (d) Non-US users using PayPal violates Billed's terms of service and will result in account suspension.(e) You will not use PayPal for money-laundering, and you will report any suspicious activity or parties knowingly engaged in money-laundering to support@billed-app.com. (f) That your use complies with all local, state, and federal regulations in your jurisdiction. (g) That all usage is in compliance with the PayPal Acceptable Usage Policy. You can view the PayPal Acceptable Usage Policy on the PayPal website.
  • **Venmo:** Some users, at Billed’s sole discretion, may have access to features that interact with Venmo (“Venmo features”). These features are built with Venmo deep links and APIs and include deep links to prepopulate some information (email, amount, description) within the Venmo interface. These features can also automatically record payments and their status with information provided by Venmo to Billed. You agree that (a) your use complies with all local, state, and federal regulations in your jurisdiction; (b) you are a legitimate US user of Venmo; and (c) all usage is in compliance with the PayPal Acceptable Usage Policy. (https://www.paypal.com/us/legalhub/acceptableuse-full).`

  const paragraphs = text.split('\n');

  const applyStyles = (line) => {
    // Trim the line to remove any leading whitespace
    const trimmedLine = line.trim();

    // Replace hashtags with heading tags
    const withHeadings = trimmedLine.replace(/^# (.+)/g, (match, p1) => `<h2 style="font-weight: bold; font-size: 1.8rem;">${p1}</h2>`);

    // Replace double asterisks with bold tags
    const withBold = withHeadings.replace(/\*\*(.+?)\*\*/g, (match, p1) => `<strong>${p1}</strong>`);

    // Return line as HTML using dangerouslySetInnerHTML
    return <p dangerouslySetInnerHTML={{ __html: withBold }} />;
  };



  return (
    <div className="mx-auto border-b px-6 pt-8 pb-6 flex flex-col justify-center">
      <div className="max-w-[800px] mx-auto py-16">

        {/* Header */}
        <div className="text-4xl font-bold py-8">
          <p>Billed Terms of Service</p>
        </div>

        {/* Text */}
        <div className="text-xl space-y-6 leading-10">
          {paragraphs.map((paragraph, index) => applyStyles(paragraph, index))}
        </div>
      </div>
    </div>
  );
}

export default TermsOfService
