import React from 'react';
import ScanTheCheckPhone from "../Assets/Images/HowItWorks/ScanTheCheck.png"
import Method from "../Assets/Images/HowItWorks/Method.png"
import Split from "../Assets/Images/HowItWorks/Split.png"
import GetPaid from "../Assets/Images/HowItWorks/GetPaid.png"

function HowItWorks() {

  const headers = [
    { text: 'Scan the Check', desc: 'Snap a picture, upload from camera roll, or enter your check manually.', img: ScanTheCheckPhone },
    { text: 'Splitting Method', desc: 'Choose from even, itemized, or percentage split options.', img: Method },
    { text: 'Split the Bill', desc: 'Follow Billed’s intuitive directions to split your bill with ease.', img: Split },
    { text: 'Get Paid', desc: 'Request payment online or send texts to notify your friends.', img: GetPaid },
  ];

  return (
    <div id="how-it-works" className='w-full pt-24 my-20'>
      <div className='w-full px-20 flex flex-col'>
        {headers.map((header, index) => (
          <div key={header.text} className="w-full flex flex-col sm:flex-row justify-evenly items-center gap-4 my-12">
            <div className="w-full sm:w-1/2 max-w-[34rem] md:px-10 flex flex-col sm:flex-row gap-2 sm:gap-4 items-center justify-center">
              <div className="rounded-full text-4xl font-bold min-w-16 min-h-16 w-16 h-16 bg-viridian text-white flex justify-center items-center">
                {index + 1}
              </div>
              <div>
                <h2 className='text-4xl font-bold text-center sm:text-left'>
                  {header.text}
                </h2>
                <p className="text-center sm:text-left">{header.desc}</p>
              </div>
            </div>
            <div className="mt-10 sm:mt-0 w-full sm:w-1/3 flex justify-center">
              <img src={header.img} className="w-[15rem]" alt={header.text}/>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default HowItWorks;
