import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import MockupGraphic from '../Assets/Images/AlternativeMockupGraphic.png';

function Hero() {

  // State for storing the width of the screen
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // Effect to update the width on screen resize
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Function to calculate the width for the image based on the screen width
  const getImageWidth = (width) => {
    if (width < 600) {
      return '300px'; // Example for very small screens
    } else if (width >= 600 && width < 1024) {
      return '350px'; // Small screens
    } else if (width >= 1024 && width < 1600) {
      return '375px'; // Large screens
    } else if (width >= 1600) {
      return '450px'; // Larger screens
    }
  };

  useEffect(() => {
    console.log(screenWidth);
  }, [screenWidth]);

  const imageWidth = getImageWidth(screenWidth);

  return (
    <div className="relative w-screen border-b">
      {/* Navbar will be fixed at the top */}
      <div className="fixed bg-cloud md:border-0 top-0 w-full z-50">
        <Navbar />
      </div>

      {/* Hero image container */}
      <div className="px-4 max-w-6xl mx-auto md:h-screen h-max w-screen">
        <div className="mt-16 md:mt-0 w-full h-full flex flex-col gap-10 md:gap-0 md:flex-row items-center">

          {/* Headings */}
          <div className="w-full text-center md:text-left my-10 md:my-0 md:w-1/2">
            <div className="text-carbon flex max-w-[475px] justify-center flex-col gap-4 mx-auto">
              <h1 className="text-5xl md:text-6xl w-full font-bold px-2overflow-wrap">Splitting the Bill, Simplified.</h1>
              <h3 className="text-carbon w-full text-xl px-2">Split the bill between you and your friends just by scanning the check.</h3>
              <a href="#get-notified" className="hover:opacity-90 transition text-white bg-viridian mx-auto md:mx-0 w-max px-6 py-3 rounded-full">
                <p className="">Get Notified on Launch</p>
              </a>
            </div>
          </div>

          {/* Image */}
          <div className="md:absolute right-0 bottom-0 z-40 flex flex-col gap-4 md:w-1/2 items-center sm:pt-20 justify-center">
            <img className="" src={MockupGraphic} alt="Mockup" style={{ width: imageWidth }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
