import React from 'react'

function PrivacyPolicy() {

  const text = `Last updated: January 21, 2024
  Welcome to Billed’s Privacy Statement. Your right to privacy and online security is important. This Privacy Statement describes Billed Inc.’s collection, protection, disclosure, and use of your information. This Privacy Statement applies to all of Billed’s websites, mobile applications, and/or online services, that link to or reference this Privacy Statement. It covers your personal information that is provided or collected through the use of our services and how we use that information in connection with our services.
  By using Billed, you agree to this Privacy Statement. If you do not agree with any term in this Privacy Statement, please do not use our services.
  Billed is not intended for use by children under 13 years of age or minors. We do not knowingly collect personally identifiable information from children under 13. If a parent or guardian becomes aware that their child has provided us with Personal Information without their consent, they should contact us at support@billed-app.com. If we become aware that a child under 13 has provided us with Personal Information, we will delete such information from our files immediately.

  # Data We Collect
  When you use Billed, we may collect the following information about you:
  • **Registration information:** When you sign up for an account with Billed, you will be asked for registration information, such as your name, email address, phone number, and a password. We require you to provide an email address in order to use Billed so that we can contact you about your account, and so that you can access your data from any device. Friends will be able to add you using your email address. We require you to provide a name for your account, this is the name your friends will see when adding expenses with you. Providing a phone number gives your friends another way to find you and adds an extra layer of security to your account. You can make changes to this information at any point from your Account Settings page or by emailing us at support@billed-app.com.
  • **User-generated content:** We also collect and store the information you provide to us when using our service to share expenses, payments, and communicate with others. This data includes, for example, group names, expense descriptions and amounts, payments and their confirmation numbers, comments and reminders, receipt images, notes, and memos, in addition to any other information that you attach or share while using Billed. You may share this information with contacts you specify in the course of using the Service. For example, if you add an expense with a group of roommates, those roommates will be able to see that bill, while users who are not the roommates group will not. You are solely responsible for which contacts you share this information with.
  • **User-generated invitations / Adding users to a group:** Invitations to a specifically chosen friend or set-of-friends to join Billed in general, or a specific relationship or group, can be sent at your request on your behalf. When you invite a friend or contact to Billed using these functions, Billed will collect from you the contact information of the person(s) being invited by asking you to provide a name, email, phone number and/or an internal Billed identifier. A few messages will be sent to these contacts through the chosen communication channel or a link will be provided for you to send the invitation yourself. Your name, the contact name, and the name of the associated group may also be incorporated in invitation messages sent by Billed, as applicable.
  • **Contact Book / Address Book data:** On our mobile applications for Android and iOS, our app uses on-device contact book data (when you give permission in the relevant operating system) to locally display your list of contacts. This also allows you to auto-fill the full name, email address and/or phone number of a given contact to whom you are sending an invitation to Billed, or inviting to a relationship or group. If you give Billed this contact book permission, only contacts you specifically interact with in the user interface will have their name, email and/or phone number collected by Billed for the purpose of sending these invitations and creating a profile in your group or friendship. Some additional contact book metadata about contacts may be collected by Billed, such as the total number of phone numbers for a contact you select.
  • **The way you use our service:** We collect information about how you use and interact with Billed. Such as the types of expenses you add, the features you use, the actions you take, and the time, frequency and duration of your activities. For example, we log when and how often you add images to a bill and may use that information to improve our service or suggest new features we offer.
  • **Information Shared By Others:** Other Billed users may share information with you using your contact information (such as email or phone number), and that information will be stored in the course of using Billed. For instance, if a contact of yours shares a bill with you or adds you to a group using your email address, Billed may notify you and store that information under your account according to your preferences.
  • **Information related to Third Party Integrations:** Billed may collect information about you from third parties to help improve our service, or for security and support reasons. If you choose to use some of the optional integrations the third party services that we offer we may collect information about you from those services. For example, if you choose to send payments to your friends using one of the payment services we integrate with we may collect information from that payment service (i.e. your username for that service, the status of that payment).
  • **Google accounts:** As part of using Billed, you may be presented with the option to link one or more parts of your Google account to your Billed account. If you authorize Billed to use your Google Account to login to Billed, your name, contact information (usually an email address) and other basic account details (profile picture, age, gender, birthday) may be shared with Billed at your discretion. Sharing this data is not required to start a Billed account. These details will be stored by Billed and will generally be treated as if you had manually provided that data to Billed under this privacy policy (for instance: to sign in with Google in the future, to contact you, display your profile picture, reset your password, set a default currency).
  • **Other information:** We may request or receive other information such as feedback, questions, comments, suggestions, or ideas to provide you with other benefits or to improve upon the Service.

  # How We Use Data
  We will not provide any of your personal information to any third party as outlined in this policy or with your consent.
  We use your information for the following purposes:
  **To Provide the Billed Service:**
  Billed is designed to help people share expenses and informal IOUs with friends. We process your information to provide, support, and secure our service (both automatically and manually). For example, we may use your information in some of the following ways:
  • To record informal records of expenses, IOUs, and payments.
  • To fulfill your requests for certain products and Services.
  • To help your Billed contacts manage their Billed relationship with you. For example, we may display your Billed email address to your Billed friends and group members so they may identify that they are sharing bills with the correct person.
  • Respond to your inquiries about our Services. For example, if you or a Billed friend of yours contacts us for support, or as part of a random account audit, we may go into your account in "administrator mode" to help provide support to you or a friend.
  • To detect and protect against errors, fraud, or other criminal activity.
  • We may use your information for internal security purposes. For example, if you have activated a feature that limits access to a Service from a specific device, our systems will access the Device ID from each device you attempt to access the Service with to determine if the device is the specific authorized device. Additionally, we may use identifying information to monitor suspicious activity. For example, if we detect that a single account on a Site is being accessed from multiple devices, as determined by tracking the Device IDs that access an account, we may contact the user to ensure that such access has been authorized by the user.
  • To verify your identity in the event you contact us for assistance.
  • To analyze Site usage, perform market research, project planning, product development, troubleshooting problems, analysis of user behavior, internal marketing and promotions, or other efforts to improve our service quality.
  • To pre-fill form fields or store preferences for your convenience.
  • To alert you to software compatibility issues and to improve our web design and functionality.
  • To communicate with you. For example, we will email you when we release new features, have important news, and when you have a notification generated by activity (including a monthly summary). All of these emails are optional and can be set by going into your Account Settings. Some emails, such as, updates to our Privacy Policy, information about your account, password resets, or other security issues are not optional.
  • To enforce our Terms of Service
  **To allow other users to find you**
  Friends can find your account on Billed by conducting a search via your email address or phone number. When friends add you on Billed they’re only able to see what you’ve shared with them. You can manage your preference for this setting from your Account Settings page.
  **With Third Party Services**
  We may receive and collect information about you from Third Parties and affiliates that help us provide, support, and secure our services. In general, data shared with Billed in this manner will be treated under the same privacy restrictions as any other data shared with Billed. For example:
  • If you choose to settle up via one of the third party payment integrations we offer we may collect information from the service. We will use the data you’ve supplied in your account to assist in completing that payment. We may use information from that Service to display the status of that payment to you. We may also use information about payments sent via third party services to address fraud, security, or support issues.
  • If you give Billed access to your Google Contacts as part of using our service, this contact data may be used in a limited way: to send Billed invitations on your behalf to friends (to individuals specifically authorized by you; not in bulk), to locate your friends who already use Billed, to recommend friends to invite to Billed, and to improve our services (for example, to identify common email spelling errors in user support). If you share access to your Google account contacts, Billed may store some of this information for those purposes.
  **To Provide Personalized Advertisements**
  Where permitted by Applicable Law, we may use your email/phone, location, usage and expense information to show you specific advertisements or offers on our own site that we believe may be of interest to you. For instance, we may show ads for apartments cheaper than your current apartment based on the rent expense you share on our system.

  # How We Share Your Information
  We will not, without your permission, sell, publish, or share your personal information to third parties for their marketing purposes. We will not sell, publish or share the personal information you provided for the purpose of sending user-generated invitations to third parties for their marketing purposes.
  We help you track and manage shared expenses with your friends by sharing your information in the following ways:
  **To Provide the Billed Service**
  Billed is designed to help you track and share expenses with your friends. We share some of your personal information (name, email address, phone number, user avatar) with your friends and groupmates on Billed. Someone who knows your email address and/or phone number will be able to add you as a friend and see your name, email address, and user avatar. They will not be able to see expenses that you’ve added with other friends unless you include them on those expenses or in a group.
  • Billed allows you to keep track of expenses in groups. These groups are private, and information and expenses posted in a group is only visible to other members of the group. However, any user in that group may add additional members to the group, so it is the joint and sole responsibility of group members to safeguard who they invite into their group sharing platform.
  • Billed lets friends add, edit, delete and undelete content shared privately.
  **With third parties, affiliates, subsidiaries, and service providers:**
  We employ third party agents, subsidiaries, affiliates, and/or service providers to enable them to help support and improve our services. For example, maintenance services, database management, cloud hosting, web and mobile analytics, receipt scanning services and OCR. Third parties acting on our behalf that are given access to your personal information are contractually obligated to abide by our privacy practices and are not allowed to use the information for any other purposes. We may share your personal information with other third parties with your consent or instructions to do so.
  • Where permitted by law, third parties may use data provided to them to improve their own services; for instance, a third party receipt-scanning service may improve the OCR quality of their OCR service by training an algorithm on receipts you provide to Billed.
  **For legal matters**
  We reserve the right, in our sole discretion, to share or disclose your personal information with law enforcement, government officials, and other third parties:
  • to respond to subpoenas, court orders, or legal process;
  • in order to investigate, prevent, defend against, or take other action regarding violations of our Terms of Service, illegal activities, suspected fraud, or situations involving potential threats to the legal rights or physical safety of any person or the security of our network, Sites, or Services;
  • to respond to claims that any posting or other content violates the rights of third parties;
  • in an emergency, to protect the health and safety of our Sites' users or the general public; or
  • as otherwise required by any applicable law.
  **Business Transfer**
  If our company or our assets are acquired by another company, or in the event of a merger, consolidation, change in control, transfer of substantial assets, reorganization, or liquidation, we may transfer, sell, or assign to third parties, information concerning your relationship with us, including, without limitation, personal information that you provide and other information concerning your relationship with us. Such third parties will assume responsibility for the personal information collected by us in connection with our business operations or through our Sites and such third parties will assume the rights and obligations regarding such information as described in this Privacy Statement and according to applicable regulations.
  **API Usage**
  Billed provides a third party API which allows other applications which you sign up for to have access to the data you have provided Billed. You are in control of this process - we do not disclose any data to these third parties unless you specifically authorize your account with their application by using the Billed username and password prompt provided. We do not control these third parties and do not warrant that they are safe or non-fraudulent, and you agree that Billed is not responsible, liable for anything which occurs on these services. You indemnify Billed Inc. from all damages if you authorize your Billed account with one of these 3rd party applications.
  **Non-identifiable information**
  We may disclose information that does not personally identify you in any other manner that we deem appropriate, including to third parties for their research, marketing, and promotional purposes and to help us determine how we can improve our Sites. We will also disclose non-personal information to our service providers and other third parties about how our users collectively use the Sites.

  # Security
  **How We Protect The Information We Collect:**
  We take the security of your information seriously. We use technical and administrative security measures such as but not limited to encryption techniques and authentication procedures, among others, to maintain the security of your online session. Please understand, however, that while we try our best to safeguard your personal information once we receive it, no transmission of data over the Internet or any other public network can be guaranteed to be 100% secure.
  You can help protect the privacy of your own information by using encryption and other techniques to prevent unauthorized interception of your personal information. You are responsible for the security of your information when using unencrypted, public or otherwise unsecured networks.
  We will contact you regarding any breach of the security, confidentiality, or integrity of your unencrypted electronically stored personal information to you via email or conspicuous posting on our Site in the most expedient time possible and without unreasonable delay, insofar as consistent with (i) the legitimate needs of law enforcement or (ii) any measures necessary to determine the scope of the breach and restore the reasonable integrity of the data system.
  **Phishing:**
  Identity theft and the practice currently known as "phishing" are of great concern to Billed®. Safeguarding information to help protect you from identity theft is a top priority. We do not and will not, at any time, request your credit card information, your account ID, login password, or national identification numbers in a non-secure or unsolicited e-mail or telephone communication. For more information about phishing, visit the Federal Trade Commission's website at http://www.ftc.gov.

  # Choice and Access; Deleting Your Account; Data Retention
  **Choice and Access to your personal information:**
  You may access, review, and update the personal information you have submitted to us at any time. You can make changes to your personal information from your Account Settings page. If you need help updating or correcting your information please contact us at support@billed-app.com.
  Users can export data from their groups and friendships by following the instructions found here. We’re happy to help provide exports from your account. You can request exports by contacting us at support@billed-app.com.
  **Your Choices Regarding the Data We Collect and Process**
  In many instances, you have choices about the information you provide and limiting how we use your information. These choices, and any related consequences, are described in detail below:
  • **Personal information:** You may choose not to provide your personal information, such as your name, address, phone number or payment information, but then you might not be able to take advantage of many of our Sites’ features.
  • **Emails, Newsletters, and other communications:** We may send periodic Site related alerts, monthly summaries, notifications, newsletters, promotions, or other information via email or push notifications. You may choose whether or not you’d like to receive alerts, summaries, newsletters and promotions by indicating your preference in your account profile or settings. You may also follow the unsubscribe instructions in the email you receive. Please note that certain Services-related messages (such as password resets) that we send are necessary for the proper functioning and use of the Services and you may not have the ability to opt-out of those messages.
  • **Location Tracking:** Most mobile devices allow you to control or disable the use of location services by any application on your mobile device through the device’s settings’ menu.
  • **Allow friends to find you:** You can choose whether or not you want friends to know if your email address of phone number is already associated with a Billed account from your Account Settings page. Friends with your contact information will still be able to send you invitations and share expenses with you.
  •**Third parties:** You have the ability to choose whether or not you’d like to use many of the third-party integrations we offer. If you authorize an account connection from a third-party account or platform, such as a Google or Facebook account, you can manage your connection preferences from the third-party account or platform. Please refer to the privacy notice that governs the third-party platform for more information on the choices you may have.
  **Deleting your account:**
  You may choose to close your account at any time. You can close your account from your Account Settings page, or request that we close it for you by writing to us at support@billed-app.com. When you close your account in this way, we may allow you to reactivate it by writing to us at support@billed-app.com.
  **Data Retention**
  In accordance with and as permitted by applicable law and regulations, we will retain your information as long as necessary to serve you, to maintain your account for as long as your account is active, or as otherwise needed to operate our business. We may also retain personal information to comply with law, prevent fraud, resolve disputes, enforce our Terms of Service, and as permitted by applicable law.
  If you would like to fully delete your account and the information in it, you can write to us at support@billed-app.com. We will use commercially reasonable efforts to remove your personal information from our services so long as we do not have any legal reason or obligation to retain the record. It may take up to 90 days for your information to be cleared from our servers.
  When you change or delete personal information, or close your account from your Account Settings page, expenses involving you (either that you have shared or that have been shared with you) and your name may remain visible to people with whom they have been shared as part of their records (just as deleting an email account does not unsend emails that have already been sent).

  # Changes to this Privacy Statement
  We may update this Privacy Statement periodically. Changes are effective immediately upon posting. The date last revised appears at the top of this Statement.

  # Contact Us
  Got Questions?
  If you have questions or concerns regarding this Privacy Statement, our security practices, or our information handling practices please contact Billed in writing:
  • **Email:** support@billed-app.com
  • **Mailing Address:** Billed Inc., 1029 Garnett Pl, Evanston, IL, 60201, USA`

  const paragraphs = text.split('\n');

  const applyStyles = (line) => {
    // Trim the line to remove any leading whitespace
    const trimmedLine = line.trim();

    // Replace hashtags with heading tags
    const withHeadings = trimmedLine.replace(/^# (.+)/g, (match, p1) => `<h2 style="font-weight: bold; font-size: 1.8rem;">${p1}</h2>`);

    // Replace double asterisks with bold tags
    const withBold = withHeadings.replace(/\*\*(.+?)\*\*/g, (match, p1) => `<strong>${p1}</strong>`);

    // Return line as HTML using dangerouslySetInnerHTML
    return <p dangerouslySetInnerHTML={{ __html: withBold }} />;
  };



  return (
    <div className="mx-auto border-b px-6 pt-8 pb-6 flex flex-col justify-center">
      <div className="max-w-[800px] mx-auto py-16">

        {/* Header */}
        <div className="text-4xl font-bold py-8">
          <p>Billed Privacy Policy</p>
        </div>

        {/* Text */}
        <div className="text-xl space-y-6 leading-10">
          {paragraphs.map((paragraph, index) => applyStyles(paragraph, index))}
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy
