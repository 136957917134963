import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Hero from './components/Hero'
import HowItWorks from './components/HowItWorks'
import ValueProp from './components/ValueProp'
import GetNotified from './components/GetNotified'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import PageNotFound from './components/PageNotFound'
import TermsOfService from './components/TermsOfService'
import PrivacyPolicy from './components/PrivacyPolicy'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { ParallaxProvider } from 'react-scroll-parallax';

// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import { initializeApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getAnalytics, connectAnalyticsEmulator } from "firebase/analytics";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDs6cgs08FXLwsOkUIFgzAyGBDrb4heSpE",
  authDomain: "www.billed-app.com",
  projectId: "billedwebsite-4e7a7",
  storageBucket: "billedwebsite-4e7a7.appspot.com",
  messagingSenderId: "12890374071",
  appId: "1:12890374071:web:baca8fc75af40d60863232",
  measurementId: "G-DC0RPGTRQE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);

if (window.location.hostname === "localhost") {
  var database = getDatabase();
  connectDatabaseEmulator(database, "localhost", 9000);
  connectFunctionsEmulator(functions, "localhost", 5001);
  if (!sessionStorage.getItem("givenWarning")) {
    alert(
      "Initializing in emulator mode. If you aren't a developer, contact support@billed-app.com immediately."
    );
    sessionStorage.setItem("givenWarning", "true");
  }
} else {
  getAnalytics(app);
  getDatabase(app);
}

function FixedNavbar() {
  return (
    <div className="fixed bg-cloud md:border-0 top-0 w-full z-50">
      <Navbar />
    </div>
  )
}

// Component for the home page
function HomePage() {

  return (
    <div>
      <Hero />
      <ValueProp />

      <div className="border-t-2 w-full">
        <div className='mt-20 text-center justify-center rounded-full w-max mx-auto px-4 py-2'>
          <h2 className="text-3xl text-viridian font-bold">How Billed Works</h2>
        </div>
      </div>
      <HowItWorks />
      <GetNotified firebase={app} />
      <Footer />
    </div>
  );
}

// Component for the privacy policy page
function PrivacyPolicyPage() {
  return (
    <div>
      <FixedNavbar />
      <PrivacyPolicy />
      <Footer />
    </div>
  );
}

// Component for the terms of service page
function TermsOfServicePage() {
  return (
    <div>
      <FixedNavbar />
      <TermsOfService />
      <Footer />
    </div>
  );
}

// Component for the 404 page
function NotFoundPage() {
  return (
    <div>
      <FixedNavbar/>
      <PageNotFound/>
      <Footer/>
    </div>
  );
}

// Render the app
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div className="bg-cloud">
    <ParallaxProvider>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/terms-of-service" element={<TermsOfServicePage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </ParallaxProvider>
  </div>
);
