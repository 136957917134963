import React, { useEffect, useRef, useState } from 'react';
import Matter from 'matter-js';
import PeopleEating from "../Assets/Images/UndrawEating.svg";
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Venmo from "../Assets/Images/PaymentPlatforms/Venmo.png"
import Zelle from "../Assets/Images/PaymentPlatforms/Zelle.png"
import ApplePay from "../Assets/Images/PaymentPlatforms/ApplePay.png"

function ValueProp() {
  const boxRef = useRef(null);
  const canvasRef = useRef(null);
  const [isInViewport, setIsInViewport] = useState(false);
  const [toggled, setToggled] = useState(false);

  const cw = window.innerWidth;
  const ch = window.innerHeight;

  // Images are named sequentially as shown in your directory listing
  const foodImages = Array.from({ length: 99 }, (_, i) => `./FoodItems/Group ${174 + i}.png`);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        // The callback will return an array of entries, even if you're observing a single item
        if (entries[0].isIntersecting) {
          setIsInViewport(true);
        } else {
          setIsInViewport(false);
        }
      },
      { threshold: 0.2 } // Threshold at which to trigger the callback. 0.5 means when 50% of the target is visible
    );

    if (boxRef.current) {
      observer.observe(boxRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (isInViewport && !toggled) {

          let Engine = Matter.Engine;
          let Render = Matter.Render;
          let Composite = Matter.Composite;
          let Bodies = Matter.Bodies;

          let engine = Engine.create({});

          let render = Render.create({
            element: boxRef.current,
            engine: engine,
            canvas: canvasRef.current,
            options: {
              width: cw,
              height: ch,
              background: '#F8F8F8',
              wireframes: false,
            },
          });

          // Floor
          const floor = Bodies.rectangle(cw / 2, ch + 20, cw, 40, {
            isStatic: true,
            render: {
              fillStyle: 'transparent', // Set the fill style to transparent
            }
          });

          // Walls
          const wallOptions = { isStatic: true, render: { visible: false } };
          const leftWall = Bodies.rectangle(-10, ch / 2, 20, ch, wallOptions);
          const rightWall = Bodies.rectangle(cw + 10, ch / 2, 20, ch, wallOptions);

          const preloadImage = (src, callback) => {
            const img = new Image();
            img.onload = () => callback(img.src);
            img.onerror = () => console.error("Image loading failed:", src);
            img.src = src;
          };

          const createPlate = (x, y, imagePath, callback) => {
            preloadImage(imagePath, (loadedImagePath) => {
              const plateRadius = 53; // This is the radius of the circle that represents the plate
              const plate = Bodies.circle(x, y, plateRadius, {
                density: 0.04,
                frictionAir: 0.005,
                restitution: 0.85,
                render: {
                  sprite: {
                    texture: loadedImagePath,
                    xScale: 0.12,
                    yScale: 0.12
                  }
                }
              });
              callback(plate);
            });
          };

          // Add walls and floor to the world
          Composite.add(engine.world, [floor, leftWall, rightWall]);

          // Add plates randomly, quantity according to screen size
          var platesQuantity = Math.round(cw / 50);
          for (let i = 0; i < platesQuantity; i++) {
            const x = Math.round(Math.random() * cw);
            const y = Math.round(Math.random() * -ch - 20); // Negative to start above the canvas view
            // Randomly select an image from the foodImages array
            const randomImage = foodImages[Math.floor(Math.random() * foodImages.length)];
            createPlate(x, y, randomImage, (plate) => {
              Composite.add(engine.world, plate);
            });
          }

          Matter.Runner.run(engine);
          Render.run(render);

          // Set toggled to true to avoid re-rendering the scene
          setToggled(true);
    }
  }, [isInViewport, toggled]);

  return (
    <div className="w-screen h-screen">
      <div className="px-4 absolute flex-col gap-4 w-full h-full flex items-center justify-center">
        <img className="w-[15rem]" src={PeopleEating} alt="People Eating Together"/>
        <h2 className='z-0 text-5xl md:w-1/3 text-center mx-auto text-viridian font-bold'>Simplify Group Dining Payments</h2>
        <div className="flex flex-row gap-2 justify-between">
          <p>We integrate with</p>
          <img src={Venmo} alt="Venmo" className="grayscale w-6 h-6" />
          <img src={Zelle} alt="Zelle" className="grayscale w-6 h-6" />
          <img src={ApplePay} alt="Apple Pay" className="grayscale h-6" />
        </div>
        <a href="#how-it-works" className="flex flex-row gap-2 justify-center items-center px-4 py-2 rounded-full text-white bg-viridian">
          <p>See How</p>
          <FontAwesomeIcon icon={faArrowDown} />
        </a>
      </div>

      {/* Canvas for Plates */}
      <div
        ref={boxRef}
        style={{
          width: cw,
          height: ch,
        }}
        className="z-50 border-b-2">
        <canvas className="" ref={canvasRef} />
      </div>
    </div>
  );
};


export default ValueProp;
