import React, { useState } from 'react';
import { getDatabase, ref, set } from "firebase/database";
import shortUUID from 'short-uuid';
import toast, { Toaster } from 'react-hot-toast';
import Confetti from 'react-confetti'
import { useWindowSize } from "@uidotdev/usehooks";
import { Parallax, ParallaxLayer } from '@react-spring/parallax'
import ChainGraphic from '../Assets/Images/LogoChain.png';

function GetNotified({firebase}) {

  const size = useWindowSize();
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [disableConfetti, setDisableConfetti] = useState(false);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return re.test(email);
  };


  const resetEmailValidation = () => {
    setTimeout(() => {
      setIsValidEmail(true);
    }, 500);
  };

  const handleEmailUploadSuccess = () => {
    toast.success("Got it! We'll be in touch", {
      duration: 4000,
      iconTheme: {
        primary: '#00A97F'
      }
    });
    setDisableConfetti(false);
    setUploadSuccess(true);
    setTimeout(() => {
      setDisableConfetti(true);
    }, 5000);
  };

  const handleEmailUploadFailure = (error) => {
    toast.error("We encountered an error. Try again later", {
      duration: 4000,
    });
    console.error('Error saving email: ', error);
  };

  const saveEmailToDatabase = async (email) => {
    const db = getDatabase(firebase);
    const emailRef = ref(db, 'emails/' + shortUUID().generate());
    set(emailRef, email)
      .then(() => handleEmailUploadSuccess())
      .catch((error) => handleEmailUploadFailure(error));
  };

  const sendEmailToDb = async (event) => {
    event.preventDefault();

    if (!validateEmail(email)) {
      toast.error("Please enter a valid email");
      setIsValidEmail(false);
      resetEmailValidation();
      return;
    }

    setIsValidEmail(true);
    await saveEmailToDatabase(email);
    setEmail('');
  };

  return (

    <div className="relative w-screen" id="get-notified">

      <Parallax pages={1}>
        <div className="opacity-50 lg:opacity-100">
          <ParallaxLayer offset={0} speed={3}>
            <div className="h-full flex items-start">
              <img src={ChainGraphic} className="ml-4 mt-4 sm:ml-12 h-[80vh]" alt=""/>
            </div>
          </ParallaxLayer>
        </div>
        <div className="hidden lg:block">
          <ParallaxLayer offset={0} speed={2}>
            <div className='flex items-end justify-end h-full'>
              <img src={ChainGraphic} className="h-[80vh] mr-4 mb-4 sm:mr-12 scale-x-[-1]" alt=""/>
            </div>
          </ParallaxLayer>
        </div>
      </Parallax>

      {uploadSuccess &&
        <Confetti
            width={size.width}
            height={size.height}
            initialVelocityY={15}
            numberOfPieces={disableConfetti ? 0 : 200}
            colors={["#00A97F", "#7CDABC", "#252525", "#F8F8F8", "#C3C3C3"]}
        />}

      <div><Toaster/></div>

      {/* Hero image container */}
      <div className="px-4 flex flex-col relative z-20 text-center h-screen w-screen">
        <div className="flex flex-col gap-8 items-center justify-center h-full">
          <div className='flex flex-col gap-4'>
            <h2 className='text-5xl font-bold'>Get Notified</h2>
            <p className='text-lg'>Be the first to know when Billed launches in <span className="text-viridian">June 2024</span>.</p>
          </div>
          <form onSubmit={sendEmailToDb} className="flex gap-4 flex-col">
            <div className='w-max mx-auto shadow border px-2 bg-cloud py-4 rounded-full flex justify-center items-center'>
                <input
                  className='text-center w-[19rem] bg-cloud flex outline-none custom-input'
                  type="text"
                  id="email"
                  name="email"
                  autoComplete="off"
                  placeholder="enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
            </div>

            {/* Submit Button */}
            <button type="submit" className='w-max mx-auto'>
              <div className={`${email === '' && "bg-gray-400"} ${email !== '' && isValidEmail && 'bg-viridian'} ${email !== '' && !isValidEmail && "bg-red-500"} transition duration-500 px-5 py-2 rounded-full w-max mx-auto text-cloud`}>
                Sign Me Up
              </div>
            </button>
          </form>
        </div>
        <div className='text-sm text-center mb-10 text-gray-400 w-3/4 sm:w-2/3 lg:w-1/3 mx-auto'>
          <label htmlFor="consentCheckbox">By clicking Sign Me Up, I agree to receive email communications about Billed and I have read the <a className="underline" href="/privacy-policy">Privacy Policy</a>.</label>
        </div>
      </div>
    </div>
  );
}

export default GetNotified;
